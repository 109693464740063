import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Footer from '../components/footer'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import CardColumns from 'react-bootstrap/CardColumns'


import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'
import { Link, navigate } from 'gatsby'
import Carousel from 'react-bootstrap/Carousel'

import { getCompanyGlobalScore, getCompanyGlobalScoreLabel, getCompanyTotalReviews, getCompanyDrivingAddressLink } from '../utilities/companyHelpers'
import { drawHeroCanvasBackground } from '../utilities/drawingHelpers'

class RootIndex extends React.Component {

  constructor(props) {
    super(props);

    // Define the initial state:
    this.state = {
      searchText : null
    };   

  }

  componentDidMount() {
    // https://lavrton.com/using-react-with-html5-canvas-871d07d8d753/
    this.updateCanvas();
  }

  updateCanvas() {
    // https://lavrton.com/using-react-with-html5-canvas-871d07d8d753/
    drawHeroCanvasBackground(this.refs.heroCanvas);
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {  
    event.preventDefault();
  
    // stop search if text is empty
    if(!this.state.searchText)
      return;

    navigate(`/search?q=${this.state.searchText}`)
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const reviews = get(this, 'props.data.allContentfulReviewPost.edges');
    const homePage = get(this, 'props.data.allContentfulHomePage.edges');
    const categoryGroups = get(this, 'props.data.allContentfulCategoryGroup.edges');
    const firstHomePage = homePage[0].node;

    const RatingStars = props => (
      <div class="rating">  
            {props.rating >= 1 && <i class="icon_star voted"></i>}            
            {props.rating >= 2 && <i class="icon_star voted"></i>}
            {props.rating >= 3 && <i class="icon_star voted"></i>}            
            {props.rating >= 4 && <i class="icon_star voted"></i>}
            {props.rating >= 5 && <i class="icon_star voted"></i>}                  
       </div>  
    )

    const RatingStarsOfCompany = props => (
      <div class="cat_star">  
            {props.rating >= 1 && <i class="icon_star"></i>}            
            {props.rating >= 2 && <i class="icon_star"></i>}
            {props.rating >= 3 && <i class="icon_star"></i>}            
            {props.rating >= 4 && <i class="icon_star"></i>}
            {props.rating >= 5 && <i class="icon_star"></i>}                  
       </div>  
    )

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title="Confiable.club - Testimonios Reales para Impulsar tu Marca Digital" />
                    

   <main class="pattern">
		<section class="hero_single version_4">
			<div class="wrapper">
				<div class="container">
          <h3>{firstHomePage.title}</h3>
          <p>{firstHomePage.subtitle}</p>           
					<form data-netlify="false" onSubmit={this.handleSubmit}>
						<div class="row no-gutters custom-search-input-2">
							<div class="col-lg-10">
								<div class="form-group">
									<input class="form-control" name="searchText" onChange={this.handleInputChange} type="text" placeholder="¿Que estás buscando?..." />                  
									<i class="icon_search"></i>
								</div>
							</div>							
							{/* <div class="col-lg-4">
								<select class="wide">
                  <option>Todas las categorias</option>	
                  {categoryGroups && categoryGroups.map(catGroupNode => {
                    return (
                      <option key={catGroupNode.node.id}>{catGroupNode.node.name}</option>
                    )
                  })}
								</select>
                </div> */}
							<div class="col-lg-2">
								<input type="submit" value="Buscar" />
							</div>
						</div>
						
					</form>
				</div>
			</div>
		</section>


    <div class="main_categories">
			<div class="container">
				<ul class="clearfix">
        {firstHomePage.featuredCategoryGroups.map(categoryGroup => {
            return ( 
            <li key={categoryGroup.id} >
              <Link to={ '/cg/' + categoryGroup.slug } >						
                <i class={categoryGroup.iconClass}></i>
                <h3>{categoryGroup.name}</h3>						
              </Link>
            </li>
            )
        })}	
        <li>
						<Link to="/categorias/">
							<i class="icon-dot-3"></i>
							<h3>Más</h3>
						</Link>
					</li>
				</ul>
			</div>		
		</div>
		
    
        <div class="container margin_60_35">
            <div class="main_title_3">
              <span></span>
              <h2>Mejores Empresas</h2>
              <p>Conoce las mejores empresas según la opinión de los clientes.</p>
              {/* <a href="grid-listings-filterscol.html">See all</a>*/}
            </div>

        <div class="row add_bottom_30">
          {firstHomePage.bestCompanies.map(company => {
                  return (                  
                  <div key={company.id} class="col-lg-3 col-sm-6">
                    <Link to={ '/company/' + company.slug } class="grid_item small">           
                      <figure>
                        <img src={company.heroImage.fixed.src} />
                        <div class="info">
                          <RatingStarsOfCompany rating={getCompanyGlobalScore(company)} />                          
                          <h3>{company.companyName}</h3>
                        </div>
                      </figure>
                      </Link>
                  </div>
                  )
              })}				
        </div>

        <div class="main_title_3">
          <span></span>
          <h2>Empresas Recientes</h2>
          <p>Conoce las últimas empresas registradas para un mundo más confiable.</p>
          {/* <a href="grid-listings-filterscol.html">See all</a> */}
        </div>
        <div class="row add_bottom_30">
        {firstHomePage.newCompanies.map(company => {
                    return (                  
                    <div key={company.id} class="col-lg-3 col-sm-6">
                      <Link to={ '/company/' + company.slug } class="grid_item small">           
                        <figure>
                          <img src={company.heroImage.fixed.src} />
                          <div class="info">                          
                            <h3>{company.companyName}</h3>
                          </div>
                        </figure>
                        </Link>
                    </div>
                    )
                })}		
        </div>        
      </div>

      <div class="call_section" >
			<div class="wrapper">
				<div class="container margin_80_55">
					<div class="main_title_2">
						<span><em></em></span>
						<h2>Testimonios Reales</h2>
						<p>Testimonios reales de clientes, verificados por gente real.</p>
					</div>
					<div class="row reviews-container add_bottom_30">

                {reviews && reviews.slice(0, 3).sort((a, b) => b.postDate - a.postDate).map(entry => {
                    return (
                      <div key={entry.node.id} class="review-box clearfix">
                        
                        <div class="rev-content">
                          <RatingStars rating={entry.node.rating} />                          
                          <div class="rev-info">
                            {entry.node.authorOwner.name}
                          </div>                          
                          <div class="rev-text" style={{ color : 'black'}} dangerouslySetInnerHTML={{
                                    __html: entry.node.comment.childMarkdownRemark.html.slice(0,200) + '...',
                                  }}
                                />
                        </div>
                      </div>                                                                            
                    )
                  })}
            
        </div>
				
					<p class="text-center add_top_30 wow bounceIn" data-wow-delay="0.5s"><a href="account.html" class="btn_1 rounded">Register Now</a></p>
				</div>
				<canvas ref="heroCanvas" id="hero-canvas" width="1920" height="1080"></canvas>
			</div>
			
		</div>

      
      


      </main>                                                  
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHomePage {
      edges {
        node {
          id
          title
          subtitle
          heroImage {
            fixed(width: 1800) {
                width
                height
                src
                srcSet
              }
          } 
          featuredCompanies {
            id
            companyName
            slug
            heroImage {
              fixed(width: 350) {
                  width
                  height
                  src
                  srcSet
                }
            } 
            reviews {
              rating
            }
          }
          newCompanies {
            id
            companyName
            slug
            heroImage {
              fixed(width: 350) {
                  width
                  height
                  src
                  srcSet
                }
            } 
            reviews {
              rating
            }
          }
          bestCompanies {
            id
            companyName
            slug
            heroImage {
              fixed(width: 350) {
                  width
                  height
                  src
                  srcSet
                }
            } 
            reviews {
              rating
            }
          }
          featuredCategoryGroups {
            id
            name
            slug
            iconClass
          }
        }
      }
    }
    allContentfulReviewPost(sort: { fields: [postDate], order: DESC }) {
      edges {
        node {
          id          
          authorOwner {
            id
            name
          }
          comment {
            childMarkdownRemark {
              html 
            }
          }
          rating
          postDate(formatString: "MMMM Do, YYYY")          
        }
      }
    }   
    allContentfulCategoryGroup {
      edges {
        node {
          id
          name
          slug
        }
      }
    } 
  }
`
